// in src/App.js
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import i18nProvider from './i18nProvider.js';
import customRoutes from './customRoutes';
import theme from './theme';
import AdminLayout from './modules/adminLayout';
import LoginPage from './modules/auth/LoginPage';
import news from './modules/news';
import classicalContents from './modules/classicalContents';
import scientificLiteratures from './modules/scientificLiteratures';
import scientificLiteratureKeywords from './modules/scientificLiteratureKeywords';
import contentPieces from './modules/contentPieces';
import consumablesCalculatorMultiControls from './modules/consumablesCalculatorMultiControls';
import consumablesCalculatorAssays from './modules/consumablesCalculatorAssays';
import contentTemplates from './modules/contentTemplates';
import auditTrails from './modules/auditTrails';
import backendUsers from './modules/backendUsers';
import userGroups from './modules/userGroups';
import distributors from './modules/distributors';
import sharings from './modules/sharings';
import productGroups from './modules/productGroups';
import productSubGroups from './modules/productSubGroups';
import products from './modules/products';
import { qcvcImport, accImport } from './modules/dbimport';
import qcvcExport from './modules/qcvc_export';
import hasPermission from './modules/shared/hasPermission';

const history = createHistory();
const App = () => (
  <Admin
    disableTelemetry
    layout={AdminLayout}
    history={history}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    customRoutes={customRoutes}
    theme={theme}
    loginPage={LoginPage}
  >
    { permissions => [
      <Resource
        name="news"
        {...news(permissions)}
      />,

      <Resource
        name="classical_contents"
        {...classicalContents(permissions)}
      />,

      <Resource
        name="scientific_literatures"
        {...scientificLiteratures(permissions)}
      />,

      <Resource
        name="scientific_literature_keywords"
        {...scientificLiteratureKeywords(permissions)}
      />,

      <Resource
        name="content_pieces"
        {...contentPieces(permissions)}
      />,

      <Resource
        name="consumables_calculator_multi_controls"
        {...consumablesCalculatorMultiControls(permissions)}
      />,

      <Resource
        name="consumables_calculator_assays"
        {...consumablesCalculatorAssays(permissions)}
      />,

      <Resource
        name="content_templates"
        {...contentTemplates(permissions)}
      />,

      <Resource
        name="backend_users"
        {...backendUsers(permissions)}
      />,

      <Resource
        name="user_groups"
        {...userGroups(permissions)}
      />,

      <Resource
        name="distributors"
        {...distributors(permissions)}
      />,

      <Resource
        name="sharings"
        {...sharings(permissions)}
      />,

      <Resource
        name="product_groups"
        {...productGroups(permissions)}
      />,

      <Resource
        name="product_subgroups"
        {...productSubGroups(permissions)}
      />,

      <Resource
        name="products"
        {...products(permissions)}
      />,

      hasPermission(permissions, { create: ['/api/web/dbimport/qcvc'] })
        ? (
          <Resource
            name="dbimport/qcvc"
            options={{ label: 'QCVC Import', showInMenu: true }}
            {...qcvcImport()}
          />
        )
        : null,

      <Resource
        name="qcvc_export"
        {...qcvcExport(permissions)}
      />,

      hasPermission(permissions, { create: ['/api/web/dbimport/acc'] })
        ? (
          <Resource
            name="dbimport/acc"
            options={{ label: 'Acc Import', showInMenu: true }}
            {...accImport()}
          />
        )
        : null,

      hasPermission(permissions, { read: ['/api/web/app_roles'] })
        ? <Resource name="app_roles" />
        : null,

      hasPermission(permissions, { read: ['/api/web/characteristics'] })
        ? <Resource name="characteristics" />
        : null,

      hasPermission(permissions, { read: ['/api/web/classical_content_types'] })
        ? <Resource name="classical_content_types" />
        : null,

      hasPermission(permissions, { read: ['/api/web/content_types'] })
        ? <Resource name="content_types" />
        : null,

      hasPermission(permissions, { read: ['/api/web/countries'] })
        ? <Resource name="countries" />
        : null,

      hasPermission(permissions, { read: ['/api/web/qcvc_countries'] })
        ? <Resource name="qcvc_countries" />
        : null,

      hasPermission(permissions, { read: ['/api/web/languages'] })
        ? <Resource name="languages" />
        : null,

      hasPermission(permissions, { read: ['/api/web/products_tree'] })
        ? <Resource name="products_tree" />
        : null,

      hasPermission(permissions, { read: ['/api/web/products_and_groups_tree'] })
        ? <Resource name="products_and_groups_tree" />
        : null,

      hasPermission(permissions, { read: ['/api/web/scientific_literature_types'] })
        ? <Resource name="scientific_literature_types" />
        : null,

      hasPermission(permissions, { read: ['/api/web/specialities'] })
        ? <Resource name="specialities" />
        : null,

      hasPermission(permissions, { read: ['/api/web/released_content_pieces'] })
        ? <Resource name="released_content_pieces" />
        : null,

      hasPermission(permissions, { read: ['/api/web/topics'] })
        ? <Resource name="topics" />
        : null,

      <Resource
        name="audit_trails"
        {...auditTrails(permissions)}
      />,

      <Resource
        name="user_groups_search_user"
      />,

      <Resource
        name="user_roles"
      />,
    ]}
  </Admin>
);

export default App;
