import StorageIcon from '@material-ui/icons/Storage';
import hasPermission from '../shared/hasPermission';
import QcvcExport from './export';

export default permissions => ({
  options: { label: 'QCVC Export' },
  list: hasPermission(permissions, { read: ['/api/web/dbexport/qcvc'] })
    ? QcvcExport : null,
  icon: StorageIcon,
});
