import * as React from 'react';
import { Button, useNotify } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';

const API_URL = process.env.REACT_APP_API_URL;

const QcvcExport = props => {
  const notify = useNotify();
  const handleDownload = async type => {
    try {
      const response = await fetch(`${API_URL}/dbexport/qcvc_${type}_tracking`, {
        credentials: 'include',
        mode: 'cors',
      });
      if (!response.ok) {
        throw new Error('Failed to download data.');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const now = new Date();
      const dateStr = now.toISOString().replace('T', ' ').substr(0, 19);
      link.setAttribute('download', `qcvc_${type}_tracking_${dateStr}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notify('Failed to download data.', 'error');
    }
  };
  return (
    <div>
      <h1>QCVC Export</h1>
      <p>
        <Button
          onClick={() => handleDownload('analyte')}
          label="Download Analyte Tracking"
        >
          <DownloadIcon />
        </Button>
      </p>
      <p>
        <Button
          onClick={() => handleDownload('product')}
          label="Download Product Tracking"
        >
          <DownloadIcon />
        </Button>
      </p>
    </div>
  );
};

export default QcvcExport;
